/*
 * Copyright 2022 Nightingale Team
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */
import React from 'react';
import _ from 'lodash';
import Timeseries from '../dashboard/Renderer/Renderer/Timeseries';

interface IProps {
  series: any[];
}

export default function Graph(props: IProps) {
  const { series } = props;
  const graphProps = {
    custom: {
      drawStyle: 'lines',
      fillOpacity: 0,
      stack: 'hidden',
      lineInterpolation: 'smooth',
    },
    options: {
      legend: {
        displayMode: 'hidden',
      },
      tooltip: {
        mode: 'all',
      },
    },
  };

  return (
    <div style={{ height: 200 }}>
      <Timeseries values={graphProps as any} series={series} />
    </div>
  );
}
